













































































































import { Vue, Component } from "vue-property-decorator";
import { SvgIcon } from "@components";

@Component({
  components: {
    SvgIcon,
  },
})
export default class BillsPayment extends Vue {}
